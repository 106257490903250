<div>
  <nav class="navbar navbar-expand-md">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">T</a>
      <button
        class="navbar-toggler navbar-dark"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav">
          <a
            class="nav-link"
            href="#"
            (click)="goToSkills()"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavAltMarkup"
            >SKILLS</a
          >

          <a class="nav-link" href="#/about">ABOUT</a>
          <a class="nav-link contact" href="#/contact">CONTACT</a>
        </div>
      </div>
    </div>
  </nav>
</div>
